import { AppCatalog, Book } from "@/api";
import { useAllDefinitionCatalog } from "@/api/axios/useAllCatalog";
import { responseStatusErrorHandler } from "@/pages/common/error/responseStatusErrorHandler";
import { getShareCatalogCode, getStoreCatalogCode, isLinkedApp } from "@/utils/CatalogCode";
import { AppCatalogData, ShareCatalogData, StoreCatalogData } from "@/utils/DataType";

// アプリカタログ情報（+蓄積/共有定義）取得+独自の型に変換
export function useGetAppData(book: Book, catalog: AppCatalog) {
    // アプリ情報取得
    const resData: AppCatalogData = getAppCatalogData(book, catalog);

    // 蓄積定義取得
    resData.store = useGetStoreData(catalog);

    // 共有定義取得
    resData.share = useGetShareData(catalog);

    return resData;
}

export const useGetStoreData = (catalog: AppCatalog) => {
    // 蓄積定義取得
    const storeCode = getStoreCatalogCode(catalog);
    const storeCatalog = useAllDefinitionCatalog({
        codes: storeCode,
        errorHandler: responseStatusErrorHandler({
            401: "E00007",
            defaultError: "E00009",
            networkError: "E00008",
        }),
    });

    // 蓄積定義を独自の型に変換
    const store = storeCatalog.map((item) => {
        const customData: StoreCatalogData = {
            store: {
                code: {
                    _value: item.catalogItem._code._value,
                    _ver: item.catalogItem._code._ver,
                },
                eventCode: (item.template.store || [])[0].event[0].code._value,
                name: item.catalogItem.name,
                description: item.catalogItem.description,
            },
            storeCatalogId: (item.template.store ?? [])[0].id,
        };
        return customData;
    });

    return store;
};

export const useGetShareData = (catalog: AppCatalog) => {
    // 共有定義取得
    const shareCode = getShareCatalogCode(catalog);
    const shareCatalog = useAllDefinitionCatalog({
        codes: shareCode,
        errorHandler: responseStatusErrorHandler({
            401: "E00007",
            defaultError: "E00009",
            networkError: "E00008",
        }),
    });

    // 共有定義を独自の型に変換
    const share = shareCatalog.map((item) => {
        const customData: ShareCatalogData = {
            share: {
                code: {
                    _value: item.catalogItem._code._value,
                    _ver: item.catalogItem._code._ver,
                },
                eventCode: (item.template.share || [])[0].event[0].code._value,
                name: item.catalogItem.name,
                description: item.catalogItem.description,
            },
            shareCatalogId: (item.template.share ?? [])[0].id,
        };
        return customData;
    });

    return share;
};

// アプリカタログを独自の型に変換
export const getAppCatalogData = (book: Book, catalog: AppCatalog) => {
    const data: AppCatalogData = {
        code: {
            _value: catalog.catalogItem._code._value,
            _ver: catalog.catalogItem._code._ver,
        },
        name: catalog.catalogItem.name,
        description: catalog.catalogItem.description.section[0].content[0].sentence,
        userId: book.cooperation
            .filter(isLinkedApp)
            .find((cooperation) => cooperation.app._value === catalog.catalogItem._code._value)?.userId,
    };

    return data;
};
