import { LinearProgress } from "@mui/material";

export default function Loading({ isReload }: { isReload: boolean }) {
    return (
        <div
            className={
                isReload
                    ? "flex flex-col h-screen max-w-screen-sm mx-auto bg-[rgba(248,248,251,0.7)]"
                    : "flex flex-col h-screen max-w-screen-sm container mx-auto bg-secondary"
            }
        >
            <div className="f-full flex flex-col gap-12 relative top-1/3 ">
                <p className="text-center text-title text-2xl">Loading...</p>
                <div className="flex justify-center">
                    <LinearProgress sx={{ width: "40%" }} />
                </div>
            </div>
        </div>
    );
}
