import { ErrorHandler } from "@/pages/common/error/AppError";
import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../api";
import { logoutKey } from "../queryKeyConst";

export default function useLogout() {
    return useMutation({
        mutationKey: [logoutKey.all],
        mutationFn: logoutFn,
    });
}

const logoutFn = async (errorHandler: ErrorHandler) => {
    try {
        return await axiosInstance.request({
            url: "/app/logout",
            method: "POST",
        });
    } catch (e) {
        throw errorHandler(e);
    }
};
