import { useActorCatalog, useAppCatalog, useRegionCatalog } from "@/api/axios/useCatalog";
import catalog from "@/config/catalog.json";
import { useBookContext } from "@/pages/common/book/BookContext";
import { AppError } from "@/pages/common/error/AppError";
import { responseStatusErrorHandler } from "@/pages/common/error/responseStatusErrorHandler";
import { getLinkedAppCatalogCode, getRegionCatalogCode } from "@/utils/CatalogCode";
import { useGetAllianceApp } from "@/utils/useGetAllianceApp";
import { useGetAppData } from "@/utils/useGetAppData";
import { useState } from "react";
import { useOutlet, useParams } from "react-router-dom";
import { PersonalDataContext, isErrorType } from "./PersonalDataContext";

export default function PersonalDataContextProvider() {
    const outlet = useOutlet();

    const [isError, setIsError] = useState<isErrorType>(isErrorType.normal);

    const book = useBookContext();

    const { applicationCode } = useParams();
    const actorCode = catalog.app_info.find((info) => info.code === Number(applicationCode))?.actor;
    if (!applicationCode || !actorCode) {
        throw new AppError("E40001");
    }

    // 連携済みのアプリでない場合はエラーにする
    if (!getLinkedAppCatalogCode(book).some((c) => c === Number(applicationCode))) {
        throw new AppError("E40001");
    }

    const { data: actorData } = useActorCatalog({
        catalogCode: actorCode,
        errorHandler: responseStatusErrorHandler({
            401: "E40002",
            defaultError: "E40004",
            networkError: "E40003",
        }),
    });

    const actor = actorData.catalogItem._code;

    // 選択したアプリ情報
    const { data: selectedAppCatalog } = useAppCatalog({
        catalogCode: Number(applicationCode),
        errorHandler: responseStatusErrorHandler({
            401: "E40002",
            defaultError: "E40004",
            networkError: "E40003",
        }),
    });
    const selectedAppData = useGetAppData(book, selectedAppCatalog);

    // 連携アプリ情報
    const regionCode = getRegionCatalogCode(book);

    if (!regionCode) {
        throw new AppError("E40001");
    }

    const { data: regionCatalog } = useRegionCatalog({
        catalogCode: regionCode,
        errorHandler: responseStatusErrorHandler({
            401: "E40002",
            defaultError: "E40004",
            networkError: "E40003",
        }),
    });

    const allianceAppCatalogs = useGetAllianceApp({ mainApp: selectedAppData, regionCatalog: regionCatalog });

    const value = {
        actor,
        applicationCode,
        selectedAppCatalog,
        selectedAppData,
        allianceAppCatalogs,
        isError,
        setIsError,
    };

    return <PersonalDataContext.Provider value={value}>{outlet}</PersonalDataContext.Provider>;
}
