import { AppCatalog, Book, BookCooperation, BookCooperationApp, BookCooperationRegion, RegionCatalog } from "@/api";

// bookからregionカタログコード取得
export const getRegionCatalogCode = (book: Book) => {
    return book.cooperation.find(isLinkedRegion)?.region._value;
};

// bookから連携済アプリカタログコード取得
export const getLinkedAppCatalogCode = (book: Book) => {
    return book.cooperation.filter(isLinkedApp).map((cooperation) => cooperation.app._value);
};

// regionカタログから連携可能アプリカタログコード取得
export const getAppAllAllianceCatalogCode = (catalog: RegionCatalog) => {
    return catalog.template["app-alliance"].map((item) => item._value);
};

// アプリカタログから蓄積定義カタログコード取得
export const getStoreCatalogCode = (catalog: AppCatalog) => {
    return catalog.template.store?.map((item) => item._value) ?? [];
};

// アプリカタログから共有定義カタログコード取得
export const getShareCatalogCode = (catalog: AppCatalog) => {
    return catalog.template.share?.map((item) => item._value) ?? [];
};

/**
 * Bookのcooperationに対して連携済みアプリかを判定する.
 */
export function isLinkedApp(cooperation: BookCooperation): cooperation is BookCooperationApp {
    return "app" in cooperation && !!cooperation.app._value && !!cooperation.userId;
}

export function isLinkedRegion(cooperation: BookCooperation): cooperation is BookCooperationRegion {
    return "region" in cooperation && !!cooperation.region;
}
