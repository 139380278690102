import useLogout from "@/api/axios/useLogout";
import { responseStatusErrorHandler } from "@/pages/common/error/responseStatusErrorHandler";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import { Button, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginStatus } from "../Appbar";

export default function Menu({ status }: { status: loginStatus }) {
    const navigate = useNavigate();

    const transition = (url: string) => {
        window.open(url, "_blank", "noopener noreferrer");
    };

    const mutation = useLogout();
    const callbackUrl = localStorage.getItem("callbackUrl");

    // callbackURLが取得できない場合はログイン画面に遷移
    const logout = () => {
        mutation.mutate(
            responseStatusErrorHandler({
                401: "E00011",
                defaultError: "E00010",
                networkError: "E00012",
            }),
            {
                onSuccess: () => (callbackUrl ? location.replace(callbackUrl) : navigate("/", { replace: true })),
            },
        );
    };

    return (
        <div className="relative z-[5000] bg-primary">
            <MenuList sx={{ p: 0 }}>
                {/* 現状存在しないのでコメントアウト */}
                {/* <MenuItem
                    onClick={() => transition(import.meta.env.VITE_QUESTION_URL)}
                    sx={{ border: "solid 1px #d8d8db" }}
                >
                    <ListItemIcon>
                        <HelpOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>よくある質問</ListItemText>
                </MenuItem> */}
                <MenuItem
                    component={Button}
                    href={`mailto:${import.meta.env.VITE_ERROR_CONTACT_ADDRESS}`}
                    sx={{ border: "solid 1px #d8d8db" }}
                >
                    <ListItemIcon>
                        <ModeCommentOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>お問い合わせ</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => transition(import.meta.env.VITE_PRIVACY_POLICY)}
                    sx={{ border: "solid 1px #d8d8db" }}
                >
                    <ListItemIcon>
                        <DescriptionOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>プライバシーポリシー</ListItemText>
                </MenuItem>
                {status !== loginStatus.dataLinkage && (
                    <MenuItem onClick={logout} sx={{ border: "solid 1px #d8d8db" }}>
                        <ListItemIcon>
                            <LogoutOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>ログアウト</ListItemText>
                    </MenuItem>
                )}
            </MenuList>
        </div>
    );
}
