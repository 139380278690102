import { Book } from "@/api";
import city_logo from "@/assets/images/common/png/city_logo.png";
import ArrowDown from "@/assets/images/common/svg/arrow_down_fill.svg?react";
import ArrowUp from "@/assets/images/common/svg/arrow_up_fill.svg?react";
import { AppError } from "@/pages/common/error/AppError";
import { Typography } from "@mui/material";
import { useState } from "react";
import Menu from "./components/Menu";

export enum loginStatus {
    notLogin = 0,
    dataLinkage = 1,
    login = 2,
}

export default function Appbar({ book, status }: { book?: Book; status: loginStatus }) {
    const [isOpen, setIsOpen] = useState(false);

    const changeMenu = () => {
        setIsOpen(!isOpen);
    };

    const getName = () => {
        let lastName: string | undefined;
        let firstName: string | undefined;

        if (book) {
            lastName = book.identification
                .find((group) => group._code._value === 30001)
                ?.["item-group"].find((items) => items.title === "氏名")
                ?.item.find((item) => item.title === "姓")?.content;

            firstName = book.identification
                .find((group) => group._code._value === 30001)
                ?.["item-group"].find((items) => items.title === "氏名")
                ?.item.find((item) => item.title === "名")?.content;
        }

        if (!lastName || !firstName) {
            throw new AppError("E00001");
        }
        return lastName + firstName;
    };

    return (
        <div className="max-w-screen-sm w-full fixed z-[1000] mx-auto bg-primary">
            <div className="w-full flex flex-col">
                <div className="w-full flex px-4 py-2 justify-between content-center top-0 h-[54px] gap-4 border-solid border-b border-divider sm:border-x">
                    <img src={city_logo} alt={import.meta.env.VITE_CITY_NAME} className="h-9" />
                    {status !== loginStatus.notLogin && (
                        <div className="min-w-0 justify-stretch flex content-center">
                            <button
                                id="menu-button"
                                aria-controls={isOpen ? "menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={isOpen ? "true" : undefined}
                                onClick={changeMenu}
                                className="w-full"
                            >
                                <div className="flex items-center gap-x-1">
                                    <div className="min-w-0 flex justify-end items-center gap-x-0.5">
                                        <div className="min-w-0 overflow-hidden">
                                            <Typography
                                                color={"#1a1a1c"}
                                                fontSize={"16px"}
                                                lineHeight={1.2}
                                                sx={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    minWidth: 0,
                                                }}
                                            >
                                                {getName()}
                                            </Typography>
                                        </div>
                                        <p className="text-title text-label2 break-keep">さん</p>
                                    </div>
                                    <div className="flex items-center">
                                        {isOpen ? (
                                            <ArrowUp style={{ height: "16px" }} />
                                        ) : (
                                            <ArrowDown style={{ height: "16px" }} />
                                        )}
                                    </div>
                                </div>
                            </button>
                        </div>
                    )}
                </div>
                {status !== loginStatus.notLogin && isOpen ? <Menu status={status} /> : null}
            </div>
        </div>
    );
}
