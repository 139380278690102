import axios from "axios";
import { AppError, ErrorCode, ErrorHandler } from "./AppError";

export const responseStatusErrorHandler = (statusErrorMap: {
    [code: number]: ErrorCode;
    defaultError: ErrorCode;
    networkError: ErrorCode;
}): ErrorHandler => {
    return (error) => {
        if (axios.isAxiosError(error)) {
            if (!error.response) {
                // 通信エラー
                return new AppError(statusErrorMap.networkError, { cause: error });
            }

            const errorCode = error.response?.status && statusErrorMap[error.response.status];
            if (errorCode) {
                return new AppError(errorCode, { cause: error });
            }

            return new AppError(statusErrorMap.defaultError, { cause: error });
        }
        return new AppError("E00001", { cause: error });
    };
};
