import errorImg from "@/assets/images/common/png/error.png";
import error_messages from "@/config/error_messages.json";
import Appbar, { loginStatus } from "@/pages/components/appbar/Appbar";
import { Button, Paper } from "@mui/material";
import { FallbackProps } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { AppError, ErrorCode } from "./AppError";

export default function Error({ error }: Partial<FallbackProps>) {
    const [searchParams] = useSearchParams({ errorCode: "" });
    const errorCode = searchParams.get("errorCode") as ErrorCode | null;

    let appError: AppError;
    if (errorCode) {
        appError = new AppError(errorCode);
    } else if (error instanceof AppError) {
        appError = error;
    } else {
        appError = new AppError("E00001", { cause: error });
    }

    const callbackUrl = localStorage.getItem("callbackUrl");

    const backApp = () => {
        window.open(callbackUrl!, "_blank", "noopener noreferrer");
    };

    const login = () => {
        location.replace("/");
    };

    return (
        <div className="component-container">
            <Appbar status={loginStatus.notLogin} />

            <div className="component-body sm:border-x border-divider">
                <div className="m-2">
                    <Paper sx={{ px: "16px", py: "40px", my: "24px", border: "none" }}>
                        <div className="flex flex-col gap-8">
                            <p className="text-center text-title text-3xl">処理を実行できませんでした</p>
                            <img src={errorImg} />
                            <div className="flex flex-col px-2 gap-8">
                                <div className="flex flex-col">
                                    <div className="flex justify-start gap-1">
                                        <p className="text-body text-label1">エラーコード</p>
                                        <p className="text-alert text-label1 font-semibold">{appError.errorCode}</p>
                                    </div>

                                    <p className="text-body text-label1">{error_messages[appError.errorCode]}</p>
                                </div>
                                <div className="flex flex-col gap-6">
                                    <p className="text-body text-label1">
                                        問題が解決しない場合は、{import.meta.env.VITE_ERROR_CONTACT_NAME}
                                        にお問い合わせください。お問い合わせには、エラー番号が必要となります。
                                    </p>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-body text-label2">
                                            担当：{import.meta.env.VITE_ERROR_CONTACT_NAME}
                                        </p>
                                        <div className="flex justify-start">
                                            <p className="text-body text-label2">メールアドレス：</p>
                                            <Button
                                                href={`mailto:${import.meta.env.VITE_ERROR_CONTACT_ADDRESS}`}
                                                variant="text"
                                                size="large"
                                                sx={{ fontSize: "12px", width: "auto" }}
                                            >
                                                {import.meta.env.VITE_ERROR_CONTACT_ADDRESS}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Paper>

                    <div className="flex justify-center">
                        {callbackUrl ? (
                            <Button onClick={backApp} variant="contained" size="large">
                                アプリにもどる
                            </Button>
                        ) : (
                            <Button onClick={login} variant="contained" size="large">
                                ログイン画面にもどる
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
