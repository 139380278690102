import { ErrorHandler } from "@/pages/common/error/AppError";
import { useSuspenseQueries } from "@tanstack/react-query";
import { AppCatalog, DefinitionCatalog } from "..";
import { allAppCatalogKey, allDefinitionCatalogKey } from "../queryKeyConst";
import { catalogFn } from "./useCatalog";

// 複数件のアプリカタログ
export function useAllAppCatalog({ codes, errorHandler }: { codes: number[]; errorHandler: ErrorHandler }) {
    const res = useSuspenseQueries({
        queries: codes.map((code) => ({
            queryKey: allAppCatalogKey.detail(code),
            queryFn: () => catalogFn<AppCatalog>({ catalogCode: code, errorHandler: errorHandler }),
        })),
    });

    const errorResults = res.filter((res) => res.isError);

    if (errorResults.length !== 0) {
        throw errorResults[0].error;
    }

    return res.map((res) => res.data);
}

// 複数件の蓄積定義・共有定義カタログ
export function useAllDefinitionCatalog({ codes, errorHandler }: { codes: number[]; errorHandler: ErrorHandler }) {
    const res = useSuspenseQueries({
        queries: codes.map((code) => ({
            queryKey: allDefinitionCatalogKey.detail(code),
            queryFn: () => catalogFn<DefinitionCatalog>({ catalogCode: code, errorHandler: errorHandler }),
        })),
    });

    const errorResults = res.filter((res) => res.isError);

    if (errorResults.length !== 0) {
        throw errorResults[0].error;
    }

    return res.map((res) => res.data);
}