import { AppCatalog } from "@/api";
import { CodeObject } from "@/api/model/codeObject";
import { AppCatalogData } from "@/utils/DataType";
import { Dispatch, createContext, useContext } from "react";

export enum isErrorType {
    normal = "normal",
    accesslogError = "アプリ間のデータ共有のログ取得に失敗しました。申し訳ございませんが、もう一度やりなおしてください。",
    bookSearchError = "保存中のデータ取得に失敗しました。申し訳ございませんが、もう一度やりなおしてください。",
    deleteerror = "保存データの削除に失敗しました。",
}

export type personalDataContextType = {
    actor: CodeObject;
    applicationCode: string;
    selectedAppCatalog: AppCatalog;
    selectedAppData: AppCatalogData;
    allianceAppCatalogs: AppCatalog[];
    isError: isErrorType;
    setIsError: Dispatch<React.SetStateAction<isErrorType>>;
};

export const PersonalDataContext = createContext({} as personalDataContextType);

export function usePersonalDataContext() {
    return useContext(PersonalDataContext);
}
