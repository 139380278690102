import { lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { createBrowserRouter, Outlet, ScrollRestoration } from "react-router-dom";
import BookContextProvider from "./pages/common/book/BookContextProvider";
import Error from "./pages/common/error/Error";
import DataLinkageAppContextProvider from "./pages/dataLinkage/common/DataLinkageAppContextProvider";
import PersonalDataContextProvider from "./pages/personalData/common/PersonalDataContextProvider";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <>
                <ScrollRestoration
                    getKey={(location) => {
                        return location.key;
                    }}
                />
                <ErrorBoundary FallbackComponent={Error}>
                    <Outlet />
                </ErrorBoundary>
            </>
        ),
        children: [
            {
                path: "",
                Component: lazy(() => import("./pages/login/Login")),
            },
            {
                path: "enter-code",
                Component: lazy(() => import("./pages/login/IntegrateLogin")),
            },
            {
                path: "error",
                element: <Error />,
            },
            {
                path: "userPolicy",
                element: <BookContextProvider />,
                children: [
                    {
                        path: "",
                        Component: lazy(() => import("./pages/userPolicy/UserPolicy")),
                    },
                ],
            },
            {
                path: "top",
                element: <BookContextProvider />,
                children: [
                    {
                        path: "",
                        Component: lazy(() => import("./pages/top/Top")),
                    },
                ],
            },
            {
                path: "dataLinkage",
                element: <BookContextProvider />,
                children: [
                    {
                        path: "",
                        element: <DataLinkageAppContextProvider />,
                        children: [
                            {
                                path: "",
                                Component: lazy(() => import("./pages/dataLinkage/select/DataLinkageSelect")),
                            },
                            {
                                path: "check",
                                Component: lazy(() => import("./pages/dataLinkage/check/DataLinkageCheck")),
                            },
                            {
                                path: "completed",
                                Component: lazy(() => import("./pages/dataLinkage/completed/DataLinkageCompleted")),
                            },
                        ],
                    },
                ],
            },
            {
                path: "personalData",
                element: <BookContextProvider />,
                children: [
                    {
                        path: ":applicationCode",
                        element: <PersonalDataContextProvider />,
                        children: [
                            {
                                path: "",
                                Component: lazy(() => import("./pages/personalData/linkage/PersonalDataLinkage")),
                            },
                            {
                                path: "history",
                                Component: lazy(
                                    () => import("./pages/personalData/history/PersonalDataLinkageHistory"),
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "withdraw",
                element: <BookContextProvider />,
                children: [
                    {
                        path: "",
                        Component: lazy(() => import("./pages/withdraw/Withdraw")),
                    },
                    {
                        path: "check",
                        Component: lazy(() => import("./pages/withdraw/WithdrawCheck")),
                    },
                    {
                        path: "completed",
                        Component: lazy(() => import("./pages/withdraw/WithdrawCompleted")),
                    },
                ],
            },
        ],
    },
]);
