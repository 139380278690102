import { CodeObject } from "@/api/model/codeObject";
import { AppCatalogData } from "@/utils/DataType";
import { Dispatch, createContext, useContext } from "react";

export type dataLinkageContextType = {
    actor: CodeObject;
    mainApp: AppCatalogData;
    integrateApp: AppCatalogData[];
    agreementDataCode: number[] | undefined;
    setAgreementDataCode: Dispatch<React.SetStateAction<number[] | undefined>>;
    redirectUrl: string;
    setRedirectUrl: Dispatch<React.SetStateAction<string>>;
    isChecked: boolean;
    setIsChecked: Dispatch<React.SetStateAction<boolean>>;
};

export const DataLinkageAppContext = createContext<dataLinkageContextType>({} as dataLinkageContextType);

export function useDataLinkageContext() {
    return useContext(DataLinkageAppContext);
}
