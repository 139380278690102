import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Router";
import Loading from "./pages/common/Loading";
import { AppError } from "./pages/common/error/AppError";
import { createMuiTheme } from "./theme";

function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: (count, error) => {
                    // 応答がある場合はアプリケーションエラーのため、リトライしない
                    let tmp: unknown = error;
                    if (error instanceof AppError) {
                        tmp = error.cause;
                    }
                    if (axios.isAxiosError(tmp)) {
                        if (tmp.response) {
                            return false;
                        }
                    }
                    return count < 2;
                },
            },
            mutations: {
                throwOnError: true,
                retry: false,
                gcTime: 0,
            },
        },
    });

    const muiTheme = createMuiTheme();

    return (
        <ThemeProvider theme={muiTheme}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<Loading isReload={false} />}>
                    <RouterProvider router={router} />
                </Suspense>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
