import { ErrorHandler } from "@/pages/common/error/AppError";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Book } from "..";
import { axiosInstance } from "../api";
import { bookKey } from "../queryKeyConst";

export default function useBook(errorHandler: ErrorHandler) {
    return useSuspenseQuery({
        queryKey: [bookKey.all],
        queryFn: () => bookFn(errorHandler),
        gcTime: 0,
        staleTime: 0,
    });
}

const bookFn = async (errorHandler: ErrorHandler) => {
    try {
        const res = await axiosInstance.request<Book>({
            url: import.meta.env.VITE_PXR_API_BASE_PATH,
            method: "GET",
            params: { path: "/book-manage/" },
        });

        return res.data;
    } catch (e) {
        throw errorHandler(e);
    }
};
