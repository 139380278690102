import axios from "axios";

// APIの処理はファイル分ける
export const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    withCredentials: true,
    // CSRF対策に使用するCookie名とヘッダ名
    xsrfCookieName: "xsrf-token",
    xsrfHeaderName: "X-XSRF-TOKEN",
    withXSRFToken: true,
});
