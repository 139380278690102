import error_messages from "@/config/error_messages.json";

export type ErrorCode = keyof typeof error_messages;

export type ErrorHandler = (error: unknown) => AppError;

export class AppError extends Error {
    static {
        this.prototype.name = "AppError";
    }
    readonly errorCode: ErrorCode;
    constructor(errorCode: ErrorCode, options?: ErrorOptions) {
        super(errorCode, options);
        this.errorCode = errorCode;
    }
}
