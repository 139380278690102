import { RegionCatalog } from "@/api";
import { useAllAppCatalog, useAllDefinitionCatalog } from "@/api/axios/useAllCatalog";
import { responseStatusErrorHandler } from "@/pages/common/error/responseStatusErrorHandler";
import { getAppAllAllianceCatalogCode, getStoreCatalogCode } from "./CatalogCode";
import { AppCatalogData } from "./DataType";

type DefintionCodeListType = {
    store: number;
    event: number;
};

// メインアプリの共有定義のeventCodeを蓄積定義のeventCodeに持つ連携可能アプリを抽出
export function useGetAllianceApp({
    mainApp,
    regionCatalog,
}: {
    mainApp: AppCatalogData;
    regionCatalog: RegionCatalog;
}) {
    // メインアプリを除いた連携可能アプリ取得
    const allianceAppCode = getAppAllAllianceCatalogCode(regionCatalog).filter((code) => code !== mainApp.code._value);
    const allianceAllApp = useAllAppCatalog({
        codes: allianceAppCode,
        errorHandler: responseStatusErrorHandler({
            401: "E10002",
            defaultError: "E10004",
            networkError: "E10003",
        }),
    });

    // 連携可能アプリのstoreCatalog
    const allianceAppStoreCodes: number[] = allianceAllApp.map((catalog) => getStoreCatalogCode(catalog)).flat();
    const allianceAppStoreCatalogs = useAllDefinitionCatalog({
        codes: allianceAppStoreCodes,
        errorHandler: responseStatusErrorHandler({
            401: "E10002",
            defaultError: "E10004",
            networkError: "E10003",
        }),
    });
    // 連携可能アプリの蓄積定義からstorecode＋eventcode取得
    const allianceStoreEventCodes: DefintionCodeListType[] = allianceAppStoreCatalogs.map((catalog) => {
        return {
            store: catalog.catalogItem._code._value,
            event: (catalog.template.store || [])[0].event[0].code._value,
        };
    });

    // メインアプリ共有定義のeventCode=連携可能アプリ蓄積定義のeventCodeの連携可能アプリstoreCode取得
    const allianceCodes = allianceStoreEventCodes.filter(
        (code) => mainApp.share?.some((share) => share.share.eventCode === code.event),
    );

    // メインアプリの共有定義のeventCodeを蓄積定義のeventCodeに持つ連携可能アプリを抽出
    const allianceApp = allianceAllApp.filter(
        (catalog) => catalog.template.store?.some((store) => allianceCodes.some((code) => store._value === code.store)),
    );

    return allianceApp;
}
