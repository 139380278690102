import { createTheme } from "@mui/material";

export function createMuiTheme() {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#1665ff",
                contrastText: "#ffffff",
            },
            secondary: {
                main: "#1a1a1c",
            },
        },
        components: {
            MuiContainer: {
                defaultProps: {
                    component: "main",
                    disableGutters: true,
                },
                styleOverrides: {
                    root: {
                        maxWidth: "640px",
                        width: "100%",
                        userSelect: "none",
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        width: "100%",
                        backgroundColor: "#ffffff",
                        borderRadius: "12px",
                        border: "solid 1px #d8d8db",
                        boxShadow: "none",
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableRipple: true,
                    disableFocusRipple: true,
                    disableTouchRipple: true,
                },
                styleOverrides: {
                    root: {
                        display: "inline-flex",
                        minWidth: "44px",
                        padding: "16px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: 0,
                        borderRadius: "8px",
                        boxShadow: "none",
                        fontSize: "15px",
                        fontWeight: 400,
                        textTransform: "none",
                        "&:hover": {
                            boxShadow: "none",
                        },
                        "&:focus": {
                            boxShadow: "none",
                        },
                        "&:active": {
                            boxShadow: "none",
                        },
                        "&.Mui-disabled": {
                            border: "none",
                            backgroundColor: "#949497",
                            color: "#ffffff",
                        },
                    },
                    sizeLarge: {
                        width: "280px",
                        fontSize: "17px",
                        fontWeight: 600,
                        lineHeight: "22px",
                    },
                    containedPrimary: {
                        "&:active": {
                            backgroundColor: "#0f47b2",
                            color: "#ffffff",
                        },
                    },
                    outlinedPrimary: {
                        border: "solid 1px #1665ff",
                        "&:hover": {
                            backgroundColor: "#fff",
                        },
                        "&:active": {
                            borderColor: "rgba(22, 101, 255, 0.5)",
                            color: "rgba(22, 101, 255, 0.5)",
                        },
                    },
                    textPrimary: {
                        padding: 0,
                        justifyContent: "start",
                        alignItems: "normal",
                        textDecoration: "underline",
                        "&:hover": {
                            textDecoration: "underline",
                            backgroundColor: "transparent",
                        },
                        "&:focus": {
                            textDecoration: "underline",
                        },
                        "&:active": {
                            color: "rgba(22, 101, 255, 0.5)",
                            textDecoration: "underline",
                        },
                    },
                    outlinedSecondary: {
                        minWidth: "60px",
                        minHeight: "44px",
                        padding: "8px",
                        border: "solid 1.5px #e2e2e4",
                        fontSize: "13px",
                        "&:hover": {
                            border: "solid 1.5px #e2e2e4",
                        },
                    },
                },
            },
            MuiIconButton: {
                defaultProps: {
                    disableRipple: true,
                    disableFocusRipple: true,
                    disableTouchRipple: true,
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "& input": {
                            minHeight: "28px",
                            padding: "8px",
                            fontSize: "13px",
                            textAlign: "center",
                        },
                    },
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    alternativeLabel: {
                        "&:completed": {
                            marginTop: 2,
                        },
                    },
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        paddingY: 0,
                        "& .MuiSvgIcon-root": {
                            fontSize: 32,
                        },
                    },
                },
            },
        },
    });

    return theme;
}
