import { ErrorHandler } from "@/pages/common/error/AppError";
import { useSuspenseQuery } from "@tanstack/react-query";
import { ActorCatalog, AppCatalog, RegionCatalog } from "..";
import { axiosInstance } from "../api";
import { actorCatalogKey, appCatalogKey, regionCatalogKey } from "../queryKeyConst";

//Regionカタログ
export function useRegionCatalog({ catalogCode, errorHandler }: { catalogCode: number; errorHandler: ErrorHandler }) {
    return useSuspenseQuery({
        queryKey: regionCatalogKey.detail(catalogCode),
        queryFn: () => catalogFn<RegionCatalog>({ catalogCode, errorHandler }),
        gcTime: 0,
    });
}

//Actorカタログ
export function useActorCatalog({ catalogCode, errorHandler }: { catalogCode: number; errorHandler: ErrorHandler }) {
    return useSuspenseQuery({
        queryKey: actorCatalogKey.detail(catalogCode),
        queryFn: () => catalogFn<ActorCatalog>({ catalogCode, errorHandler }),
        gcTime: 0,
    });
}

// アプリカタログ
export function useAppCatalog({ catalogCode, errorHandler }: { catalogCode: number; errorHandler: ErrorHandler }) {
    return useSuspenseQuery({
        queryKey: appCatalogKey.detail(catalogCode),
        queryFn: () => catalogFn<AppCatalog>({ catalogCode, errorHandler }),
        gcTime: 0,
    });
}

export async function catalogFn<T>({ catalogCode, errorHandler }: { catalogCode: number; errorHandler: ErrorHandler }) {
    try {
        const res = await axiosInstance.request<T>({
            url: import.meta.env.VITE_PXR_API_BASE_PATH,
            method: "GET",
            params: { path: `/catalog/${catalogCode}` },
        });

        return res.data;
    } catch (e) {
        throw errorHandler(e);
    }
}
