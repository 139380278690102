import { useActorCatalog, useAppCatalog, useRegionCatalog } from "@/api/axios/useCatalog";
import catalog from "@/config/catalog.json";
import { useBookContext } from "@/pages/common/book/BookContext";
import { AppError } from "@/pages/common/error/AppError";
import { responseStatusErrorHandler } from "@/pages/common/error/responseStatusErrorHandler";
import { getRegionCatalogCode, isLinkedApp } from "@/utils/CatalogCode";
import { useGetAllianceApp } from "@/utils/useGetAllianceApp";
import { getAppCatalogData, useGetAppData } from "@/utils/useGetAppData";
import { useState } from "react";
import { useOutlet } from "react-router-dom";
import { DataLinkageAppContext } from "./DataLinkageAppContext";

export default function DataLinkageAppContextProvider() {
    const outlet = useOutlet();
    const book = useBookContext();
    const [agreementDataCode, setAgreementDataCode] = useState<number[] | undefined>();
    const [redirectUrl, setRedirectUrl] = useState<string>("");
    const [isChecked, setIsChecked] = useState(false);

    const appCode = localStorage.getItem("applicationCode");
    const actorCode = catalog.app_info.find((info) => info.code === Number(appCode))?.actor;

    // 連携済みの場合はエラー
    if (
        book.cooperation.some((cooperation) => isLinkedApp(cooperation) && cooperation.app._value === Number(appCode))
    ) {
        throw new AppError("E21008");
    }

    if (!appCode || !actorCode) {
        throw new AppError("E10001");
    }

    const { data: actorData } = useActorCatalog({
        catalogCode: actorCode,
        errorHandler: responseStatusErrorHandler({
            401: "E10002",
            defaultError: "E10004",
            networkError: "E10003",
        }),
    });

    const actor = actorData.catalogItem._code;

    // メインアプリ情報取得
    const { data: appData } = useAppCatalog({
        catalogCode: Number(appCode),
        errorHandler: responseStatusErrorHandler({
            401: "E10002",
            defaultError: "E10004",
            networkError: "E10003",
        }),
    });

    const mainApp = useGetAppData(book, appData);

    if (!agreementDataCode) {
        setAgreementDataCode(mainApp.store?.map((item) => item.store.code._value) ?? []);
    }

    // 連携アプリ情報取得
    const regionCode = getRegionCatalogCode(book);

    if (!regionCode) {
        throw new AppError("E10001");
    }

    const { data: regionCatalog } = useRegionCatalog({
        catalogCode: regionCode,
        errorHandler: responseStatusErrorHandler({
            401: "E10002",
            defaultError: "E10004",
            networkError: "E10003",
        }),
    });

    // メインアプリの共有定義のeventCodeを蓄積定義のeventCodeに持つ連携可能アプリを抽出
    const allianceApp = useGetAllianceApp({ mainApp: mainApp, regionCatalog: regionCatalog });

    const integrateApp = allianceApp.map((catalog) => getAppCatalogData(book, catalog));

    const value = {
        actor,
        mainApp,
        integrateApp,
        agreementDataCode,
        setAgreementDataCode,
        redirectUrl,
        setRedirectUrl,
        isChecked,
        setIsChecked,
    };

    return <DataLinkageAppContext.Provider value={value}>{outlet}</DataLinkageAppContext.Provider>;
}
