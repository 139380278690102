import useBook from "@/api/axios/useBook";
import { useOutlet } from "react-router-dom";
import Loading from "../Loading";
import { AppError } from "../error/AppError";
import { responseStatusErrorHandler } from "../error/responseStatusErrorHandler";
import { BookContext } from "./BookContext";

export default function BookContextProvider() {
    const outlet = useOutlet();
    const { data: book, isLoading } = useBook(
        responseStatusErrorHandler({
            401: "E00002",
            403: "E00002",
            defaultError: "E00005",
            networkError: "E00003",
        }),
    );

    if (!book) {
        throw new AppError("E00005");
    } else if (book.status === 2 || book.status === 3) {
        // 退会済ユーザ
        throw new AppError("E00004");
    }

    return isLoading ? (
        <Loading isReload={false} />
    ) : (
        <BookContext.Provider value={book}>{outlet}</BookContext.Provider>
    );
}
